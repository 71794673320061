import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

const routes = [
	// {
	//   path: '/index',
	//   name: 'index',
	//   component: () => import('../views/index.vue')
	// },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },{
    path: '/',
    name: 'index',
    redirect: "/fronthome",
    component: () => import('../views/front/index'),
    children:[
      {
        path:'/fronthome',
        name:'fronthome',
        component:()=>import('../views/front/Home.vue')
      },{
        path:'/list',
        name:'list',
        component:()=>import('../views/front/List.vue')
      },{
        path:'/zhongbiaogonggao',
        name:'zhongbiaogonggao',
        component:()=>import('../views/zhongbiaogonggao.vue')
      },
	  ,{
	    path:'/news',
	    name:'news',
	    component:()=>import('../views/front/news.vue')
	  },
    ]
  }
]

const router = new VueRouter({
	mode: 'history',
	routes
})

// 重置路由
export const resetRouter = () => {
	router.matcher = new VueRouter({
		mode: 'history',
		routes
	})
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
	const storeMenus = localStorage.getItem("menus");
	if (storeMenus) {
		// 拼装动态路由
		const manageRoute = {
			path: '/',
			name: 'Manage',
			component: () => import('../views/Manage.vue'),
			redirect: "/home",
			children: [{
					path: 'person',
					name: '企业信息',
					component: () => import('../views/Person.vue')
				},
				{
					path: 'password',
					name: '修改密码',
					component: () => import('../views/Password.vue')
				},
			]
		}
		const menus = JSON.parse(storeMenus)
		menus.forEach(item => {
			if (item.path) { // 当且仅当path不为空的时候才去设置路由
				let itemMenu = {
					path: item.path.replace("/", ""),
					name: item.name,
					component: () => import('../views/' + item.pagePath + '.vue')
				}
				manageRoute.children.push(itemMenu)
			} else if (item.children.length) {
				item.children.forEach(item => {
					if (item.path) {
						let itemMenu = {
							path: item.path.replace("/", ""),
							name: item.name,
							component: () => import('../views/' + item.pagePath + '.vue')
						}
						manageRoute.children.push(itemMenu)
					}
				})
			}
		})

		// 获取当前的路由对象名称数组
		const currentRouteNames = router.getRoutes().map(v => v.name)
		if (!currentRouteNames.includes('Manage')) {
			// 动态添加到现在的路由对象中去
			router.addRoute(manageRoute)
		}
	}
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
  localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
  store.commit("setPath")
  if (!to.matched.length) {
    const menus = localStorage.getItem("menus")
    if (!menus) {
      next("/login")
    } else {
      next("/front")
    }
  } else {
    next()
  }
})

   

export default router
